import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';
import classNames from 'classnames';
import Image from 'common/Image';
import { DangerouslySetInnerHtml } from 'layout';
import { ProductBoxProps } from './models';

import './ProductBox.scss';

const ProductBox: FC<ProductBoxProps> = ({
  counter,
  title,
  link,
  text,
  image,
  featuring = false,
}) => {
  const className = classNames(
    'product-box',
    counter === 1 && 'single',
    counter === 2 && 'double',
    counter === 3 && 'triple',
    counter === 4 && 'double',
    { featuring }
  );

  return (
    <div data-testid="product-box" className={className}>
      <div className="product-box__wrapper">
        <div className="product-box__image">
          <Image imageData={image} alt={title} />
        </div>
        <div className="product-box__description-wrapper">
          {title?.length ? <div className="product-box__title">{title}</div> : null}
          {text?.length ? (
            <div className="product-box__text">
              <DangerouslySetInnerHtml html={text} />
            </div>
          ) : null}
          <Link to={link?.[0]?.url} className="product-box__link">
            {link?.[0]?.name}
          </Link>
        </div>
        <div className="product-box__shadow" />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ProductBoxFragment on ProductBox {
    image {
      ...ImageFragment
    }
    text
    title
    link {
      name
      url
    }
  }

  fragment ProductBoxWideFragment on ProductBox {
    image: imageWide {
      ...ImageFragment
    }
    text
    title
    link {
      name
      url
    }
  }
`;

export default ProductBox;
