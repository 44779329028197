import React, { FC } from 'react';
import classNames from 'classnames';
import { SectionNameProps } from './models';

import './SectionName.scss';

const SectionName: FC<SectionNameProps> = ({ text, light }) => {
  const className = classNames('section-name', light && 'light');

  return <h2 className={className}>{text}</h2>;
};

export default SectionName;
