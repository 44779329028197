import React, { FC, useState } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import parseFaqCategory from '../utils/parseFaqCategory';

import { FaqAccordionProps } from './models';
import './FaqAccordion.scss';

const FaqAccordion: FC<FaqAccordionProps> = ({
  faqItems,
  title,
  subtitle,
  buttonRedirect,
  categoryId,
}) => {
  const isLinkDisplayed = buttonRedirect[0]?.url;

  return (
    <div className="faq">
      <div className="faq-accordion" id={categoryId ? parseFaqCategory(categoryId) : undefined}>
        <div className="faq-accordion__title">
          {title ? <SectionName text={title} /> : null}
          {subtitle ? <SectionTitle text={subtitle} /> : null}
        </div>

        {faqItems.map(({ properties: { question, answer } }, index) => {
          const [isOpened, setIsOpened] = useState<boolean>(false);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Accordion key={`faq-accordion-${index}`} className="faq-accordion__list">
              <Card
                className={classNames('faq-accordion__card', {
                  'faq-accordion__card--active': isOpened,
                })}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  className="faq-accordion__header"
                  onClick={() => setIsOpened(!isOpened)}
                >
                  <div className="faq-accordion__icon-container icon" />
                  <div className="faq-accordion__question">{question}</div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="faq-accordion__answer">{answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        })}
      </div>
      <div>
        {isLinkDisplayed ? (
          <Link className="faq-accordion__redirect" to={buttonRedirect[0].url}>
            {buttonRedirect[0].name}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FaqAccordionFragment on FaqAccordion {
    properties {
      title
      subtitle
      buttonRedirect {
        name
        url
      }
      faqItems {
        properties {
          question
          answer
        }
      }
    }
  }
`;

export default FaqAccordion;
