import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import Container from 'react-bootstrap/Container';
import RelatedProductItem from '../ProductBox';
import { RelatedProductsProps } from './models';

import './RelatedProducts.scss';

const RelatedProducts: FC<RelatedProductsProps> = ({
  relatedProductList,
  sectionName,
  title,
  sectionLight = true,
  className,
}) => (
  <section data-testid="related-products" className={classNames('related-products', className)}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="related-products__top">
            {sectionName ? <SectionName text={sectionName} light={sectionLight} /> : null}
            {title ? <SectionTitle text={title} /> : null}
          </div>
        </Col>
        <Col>
          <div className="related-products__list">
            {relatedProductList.map(({ properties }) => (
              <RelatedProductItem
                key={properties.title?.replace(/' '/g, '')}
                {...properties}
                counter={relatedProductList.length}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
export const query = graphql`
  fragment RelatedProductsFragment on RelatedProductsSectionType {
    properties {
      title
      sectionName
      relatedProductList {
        properties {
          ...ProductBoxFragment
        }
      }
    }
  }
  fragment RelatedProductsWideFragment on RelatedProductsSectionType {
    properties {
      title
      sectionName
      relatedProductList {
        properties {
          ...ProductBoxWideFragment
        }
      }
    }
  }
`;
export default RelatedProducts;
