const createBreadcrumbs = (crumbs, areBreadcrumbsDisplayed, nodes, pathname) => {
  if (areBreadcrumbsDisplayed) {
    if (pathname === '/') {
      return crumbs;
    }

    return crumbs.map((item) => {
      const contextData = nodes.find((node) => {
        const location = node?.context?.breadcrumb?.location;
        const contextLocation = location ? location.replace(/(.)\/?$/, '$1') : '';

        return contextLocation === item.pathname;
      });

      if (contextData && contextData.context.breadcrumbName) {
        item.crumbLabel = contextData.context.breadcrumbName;
      } else {
        const preparedLabel = item.crumbLabel.replace(/-/g, ' ');
        item.crumbLabel = preparedLabel;
      }

      return item;
    });
  }

  return [];
};

export default createBreadcrumbs;
